import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ClipLoader, ScaleLoader } from 'react-spinners'
//
import { InputField } from '.'
import { signinUser } from '../utils'

const Signin = () => {
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Username is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async ({ username, password }, { resetForm, setStatus }) => {
      const usernameLowerCase = username.toLowerCase();
      const data = await signinUser(usernameLowerCase, password)
      if (data.success === true) {
        resetForm()
        setStatus({ success: true })

        // Redirect to the dashboard
        window.location.href =
          process.env.REACT_APP_REDIRECT_URL + `?auth_token=${data.data.token}`
      } else {
        setStatus({ success: false })
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <InputField
        name='username'
        title='Username/Email Address'
        icon={<img width='23' src='/images/user.png' alt='user' />}
        formik={formik}
      />
      <InputField
        type='password'
        name='password'
        title='Password'
        icon={<img width='20' src='/images/lock.png' alt='Password' />}
        formik={formik}
      />
      <br />
      {formik.status && formik.status.success === true && (
        <div className='formStatus success'>
          Success! Redirecting to the dashboard
          <span style={{ marginLeft: 8 }}>
            <ScaleLoader color='#fff' height={15} width={2} />
          </span>
        </div>
      )}
      {formik.status && formik.status.success === false && (
        <div className='formStatus error'>
          Invalid username or password. Please try again.
        </div>
      )}
      <button type='submit' disabled={formik.isSubmitting || !formik.isValid}>
        Submit{' '}
        {formik.isSubmitting && (
          <span style={{ marginLeft: 8 }}>
            <ClipLoader color='#fff' size={20} />
          </span>
        )}
      </button>
      {/* <h5 className='accountMsg'>
        Don't have an account? <Link to='/signup'>Sign up</Link>
      </h5> */}
    </form>
  )
}

export default Signin
