const Content = () => {
  return (
    <>
      <div className='content'>
        <img src='/images/paybilt-logo.svg' alt='Paybilt' />
        <h4>Welcome to the Paybilt Docs.</h4>
        <h3>Start accepting</h3>
        <h3>
          <span>
            Canada's best <br />
            payments.
          </span>
        </h3>
      </div>

      <div className='guilloche left'>
        <img src='/images/round-circle.png' alt='' />
      </div>
    </>
  )
}

export default Content
