import React from 'react'
import { Layout, SigninForm } from '../components'

const Signin = () => {
  return (
    <Layout>
      <div className='formWrapInner'>
        <SigninForm />
      </div>
    </Layout>
  )
}

export default Signin
