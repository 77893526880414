const InputField = ({ name, icon, title, formik, ...restProps }) => {
  const { handleChange, handleBlur, touched, errors } = formik

  return (
    <div className='inputWrap'>
      <div className='formGroup'>
        <label htmlFor={name}>
          {title}
          <span className='icon'>{icon}</span>
        </label>
        <input
          name={name}
          id={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={formik.values[name]}
          {...restProps}
        />
      </div>
      <span className='inputError'>
        {touched[name] && errors[name] ? errors[name] : null}
      </span>
    </div>
  )
}

export default InputField
