import { NavLink } from 'react-router-dom'

const Nav = () => {
  return (
    <>
      <div className='buttons'>
        <NavLink
          to='/signin'
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          Sign in
        </NavLink>
        <NavLink
          to='/signup'
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          Sign up
        </NavLink>
      </div>
    </>
  )
}

export default Nav
