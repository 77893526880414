import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Nav } from '.'
import Content from './Content'

const Layout = ({ children }) => {
  const navigate = useNavigate()

  // Get the token from the local storage
  React.useEffect(() => {
    const token = localStorage.getItem('token')
    const expiresAt = localStorage.getItem('expiresAt')
    if (token && token !== '') {
      // Check if the token is expired
      if (new Date().getTime() > expiresAt) {
        console.log('Token expired')

        localStorage.removeItem('token')
        localStorage.removeItem('expiresAt')

        navigate('/signin')
      }

      console.log('Valid Token')

      // Redirect to the dashboard
      window.location.href =
        process.env.REACT_APP_REDIRECT_URL + `?auth_token=${token}`
    }
  }, [navigate])

  return (
    <main className='main'>
      <section className='contentWrap'>
        <Content />
      </section>
      <section className='formWrap'>
        <div className='formWrapInner'>
          <div className='box'>
            <h4>Get started by signing in or signing up below.</h4>
            <p>
              Get up and running quickly and painlessly. Start by exploring our
              guides and sample transactions, then move on to developing in our
              sandbox. Enjoy the finest support in the industry every step of
              the way.
            </p>
            <p>
              <strong>Thank you for choosing Paybilt.</strong>
            </p>
          </div>

          <Nav />

          {children}
        </div>

        {/* <div className='dime'>
          <img src='/images/dime.svg' alt='' width={80} />
        </div> */}
        <div className='dime2'>
          <img src='/images/round-circle-2.png' alt='' />
        </div>
      </section>
    </main>
  )
}

export default Layout
